<template>
  <b-card>
    <div class="fama-table">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div v-else-if="error.length === 0" style="width: 100%">
        <b-button
          class="mb-2"
          type="submit"
          variant="primary"
          @click="showCreateSite"
        >
          Add New
        </b-button>
        <DxDataGrid
          id="siteTable"
          ref="data-grid"
          width="100%"
          :show-borders="true"
          :data-source="data"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :hover-state-enabled="true"
          :selection="{ mode: 'single' }"
          key-expr="id"
          exporting="exportGrid"
          @row-updated="onUpdated"
          @row-updating="onUpdating"
          @exporting="onExporting"
        >
          <DxExport :enabled="true" />
          <DxColumn data-field="id" caption="Id" :allow-editing="false" />
          <DxColumn data-field="code" caption="Code" />
          <DxColumn data-field="name" caption="Name" />
          <DxColumn
            data-field="firmNames"
            caption="Firms"
            :allow-editing="false"
          >

          </DxColumn>
          <DxColumn type="buttons" :allow-exporting="false" caption="Actions">
            <DxButton
              hint="Edit"
              icon="edit"
              :visible="true"
              :disabled="false"
              :on-click="showCreateSiteWithData"
            />
            <DxButton
              hint="Delete"
              icon="trash"
              :visible="true"
              :disabled="false"
            />
          </DxColumn>
          <DxSearchPanel :visible="true" />
          <DxColumnFixing :enabled="true" />
          <DxColumnChooser :enabled="true" />
          <DxScrolling mode="standard" />
          <DxGrouping :context-menu-enabled="true" />
          <DxGroupPanel :visible="true" />
        </DxDataGrid>
      </div>
      <DxPopup
        :visible="popupVisible"
        :close-on-outside-click="false"
        :show-close-button="false"
        :show-title="true"
        :width="400"
        :height="350"
        title="Create Site"
      >
        <DxForm
          id="form"
          :col-count="1"
          :on-content-ready="validateForm"
          :form-data="siteData"
        >
          <DxItem data-field="Code" :validation-rules="validationRules.code" />
          <DxItem data-field="Name" :validation-rules="validationRules.name" />
          <DxItem
            :editor-options="{
              dataSource: firms,
              value: '',
              displayExpr: 'name',
              valueExpr: 'id'
            }"
            data-field="Firms"
            editor-type="dxTagBox"
            :validation-rules="validationRules.firms"
          />
        </DxForm>
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="before"
          :options="saveButtonOptions"
        />
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          :options="closeButtonOptions"
        />
      </DxPopup>
    </div>
  </b-card>
</template>

<script>
import axios from '@axios';
import 'devextreme-vue/tag-box';
import { BCard, BButton } from 'bootstrap-vue';
import {
  DxDataGrid,
  DxExport,
  DxButton,
  DxColumn,
  DxScrolling,
  DxColumnFixing,
  DxSearchPanel,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping
} from 'devextreme-vue/data-grid';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxForm, DxItem } from 'devextreme-vue/form';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Workbook } from "exceljs";
import saveAs from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter";

export default {
  components: {
    DxForm,
    DxExport,
    DxButton,
    DxItem,
    DxToolbarItem,
    DxPopup,
    BButton,
    DxColumn,
    DxColumnFixing,
    DxDataGrid,
    DxScrolling,
    DxSearchPanel,
    BCard,
    DxColumnChooser,
    DxGroupPanel,
    DxGrouping
  },
  data() {
    return {
      data: [],
      firms: [],
      siteData: {},
      dataLoading: true,
      popupVisible: false,
      error: '',
      validationRules: {
        code: [{ type: 'required', message: 'Code is required.' }],
        name: [{ type: 'required', message: 'Name is required.' }],
        firms: [{ type: 'required', message: 'Firm is required.' }]
      },
      saveButtonOptions: {
        icon: 'save',
        text: 'Save',
        onClick: () => {
          const pattern = /^\d+$/;
          console.log(this.siteData);
          if (
            !this.siteData.Firms ||
            !this.siteData.Code ||
            !pattern.test(this.siteData.Code) ||
            !this.siteData.Name
          ) {
            this.showToast({
              variant: 'error',
              title: 'Error',
              text: 'Please, fill all items'
            });
            return;
          }
          if (this.siteData.updating) {
            this.postUpdateSite();
          } else {
            this.postCreateSite();
          }
        }
      },
      closeButtonOptions: {
        icon: 'close',
        text: 'Close',
        onClick: () => {
          this.popupVisible = false;
          this.siteData = {};
        }
      }
    };
  },
  mounted() {
    this.getFirms();
    this.refreshData();
  },
  methods: {
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(`Fama-Site`);
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          const excelCell = options;
          excelCell.font = { name: 'Arial', size: 12 };
          excelCell.alignment = { horizontal: 'left' };
        }
      }).then(function (a) {
        console.log(a);
        // const tableNamee = this.tableName;
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            'Fama-Site.xlsx'
          );
        });
      });
      e.cancel = true;
    },
    validateForm(e) {
      e.component.validate();
    },
    showCreateSite() {
      this.popupVisible = true;
    },
    showCreateSiteWithData(e) {
      console.log(e);
      console.log(this.siteData);
      let firmsData = [];
      e.row.data.firms.forEach((f) => {
        firmsData.push(f.id);
      });
      this.siteData = {
        id: e.row.data.id,
        Code: e.row.data.code,
        Name: e.row.data.name,
        Firms: firmsData,
        updating: true
      };
      this.popupVisible = true;
    },
    onUpdated() {
      this.showToast({
        variant: 'success',
        title: 'Success',
        text: 'Data has been updated'
      });
    },
    async onUpdating(e) {
      const data = {
        ...e.oldData,
        ...e.newData
      };
      e.cancel = this.isCancelled(`/edit-site/${data.id}`, data);
    },
    refreshData() {
      this.dataLoading = true;
      this.error = '';
      axios
        .get('/list-site')
        .then((response) => {
          if (response.status === 200) {
            this.data = response?.data?.result || [];
            this.dataLoading = false;
          } else {
            this.error = response?.data?.message || 'Unexpected API error';
            this.dataLoading = false;
          }
        })
        .catch((e) => {
          this.error = e?.response?.data?.message || e?.message;
          this.dataLoading = false;
        });
    },
    getFirms() {
      axios
        .get('/list-firm')
        .then((response) => {
          if (response.status === 200) {
            this.firms = response?.data?.result || [];
          } else {
            this.error = response?.data?.message || 'Unexpected API error';
          }
        })
        .catch((e) => {
          this.error = e?.response?.data?.message || e?.message;
        });
    },
    showToast({ variant = 'success', title, text, icon = 'XIcon' }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },
    async isCancelled(path, data) {
      let response;
      try {
        response = await axios.post(path, data);
      } catch (e) {
        this.showToast({
          title: 'Error',
          variant: 'danger',
          text: e?.response?.data?.message || e?.message
        });
        return true;
      }
      if (response.status === 200) return false;
      else {
        this.showToast({
          title: 'Error',
          variant: 'danger',
          text: response?.data?.message
        });
        return true;
      }
    },
    async postUpdateSite() {
      let response;
      const { Code: code, Name: name, Firms: firms, id: id } = this.siteData;
      try {
        response = await axios.post(`/edit-site/${id}`, {
          code,
          name,
          active: true,
          firms
        });
        if (response.status === 200) {
          this.showToast({
            variant: 'success',
            title: 'Success',
            text: 'Data has been updated'
          });
          this.popupVisible = false;
          this.siteData = {};
          this.refreshData();
        } else {
          this.showToast({
            title: 'Error',
            variant: 'danger',
            text: e?.response?.data?.message || e?.message
          });
          this.refreshData();
        }
      } catch (e) {
        this.showToast({
          title: 'Error',
          variant: 'danger',
          text: e?.response?.data?.message || e?.message
        });
      }
    },
    async postCreateSite() {
      let response;
      const { Code: code, Name: name, Firms: firms } = this.siteData;
      try {
        response = await axios.post('/create-site', {
          code,
          name,
          active: true,
          firms
        });
        if (response.status === 200) {
          this.showToast({
            variant: 'success',
            title: 'Success',
            text: 'New data has been inserted'
          });
          this.popupVisible = false;
          this.siteData = {};
          this.refreshData();
        } else {
          this.showToast({
            title: 'Error',
            variant: 'danger',
            text: e?.response?.data?.message || e?.message
          });
          this.refreshData();
        }
      } catch (e) {
        this.showToast({
          title: 'Error',
          variant: 'danger',
          text: e?.response?.data?.message || e?.message
        });
      }
    },
    siteFirmsColumn_customizeText(cellInfo) {
      let _firms = '';
      if (cellInfo.value.length > 0) {
        cellInfo.value.forEach(
          (item, index) => (_firms += `${(index ? ', ' : '') + item.name}`)
        );
        return _firms;
      } else {
        return 'Firm Not Assigned';
      }
    }
  }
};
</script>

<style lang="scss">
@import './tables.scss';
</style>
